<template>
<div>
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loadingTopics">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingTopics">
      <b-col>
        <h1>Select topics to follow</h1>
        <b-form-group label="Topics" class="mb-4">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="topicsSelected"
            :options="topicsOptions"
            name="topics"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
        <b-button class="ml-2" variant="primary" v-on:click="save">Save</b-button>
        <b-button class="ml-2" @click="$router.push('Start')">Back</b-button>
        <b-alert :show="dismissCountDown" @dismissed="dismissCountDown=0" variant="success">Saved!</b-alert>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TopicsPersonalise',
  computed: {
  },
  created: function () {
    this.$stat.log({ page: 'personalise topics', action: 'open personalise topics' })
    this.loadTopics()
  },
  data () {
    return {
      dismissCountDown: 0,
      loadingTopics: true,
      topics: '',
      topicsOptions: [],
      topicsSelected: []
    }
  },
  methods: {
    loadTopics: async function () {
      this.$logger.debug('loadTopics started')
      this.loadingTopics = true
      try {
        let apiName = 'cosmos'
        let path = '/topics'
        let response = await this.$Amplify.API.get(apiName, path)
        this.topics = _.sortBy(response, 'name')
        let temp = this.topics.map(x => {
          return { text: x.name, value: x.id }
        })
        _.remove(temp, x => {
          return x.text.includes('ARCHIVED')
        })
        this.topicsOptions = temp
        const options = window.localStorage.getItem('frmCloudFavTopics')
        if (options) {
          this.topicsSelected = JSON.parse(options)
        }
        this.loadingTopics = false
        this.$logger.debug(this.topics)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    save: function () {
      window.localStorage.setItem('frmCloudFavTopics', JSON.stringify(this.topicsSelected))
      this.dismissCountDown = 2
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
